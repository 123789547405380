import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`

export const SwitchLabel = styled.label<{ isSeat: boolean }>`
  position: relative;
  width: 80px;
  height: 40px;
  background: #1c1c1c;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  cursor: pointer;
  transition: background 0.3s;
`

export const BrandContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  padding: 0 5px;

  svg {
    width: 20px;
    height: 20px;
  }
`

export const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`

export const SwitchSlider = styled.div<{ isSeat: boolean }>`
  position: absolute;
  width: 32px;
  height: 32px;
  background: #004f71;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isSeat }) => (isSeat ? 'translateX(0)' : 'translateX(40px)')};
  z-index: 1;
`
