export const TRANSLATIONS_EN = {
  appTitle: 'Intelligent Sales',
  sidebar: {
    dashboard: 'Dashboard',
    pricing: {
      title: 'Intelligent Pricing',
      dataDigitalization: 'Data digitalization',
      mtfComparative: 'Multivariant graph',
      forecast: 'Forecast',
      createCampaign: 'Create Campaign',
      dataForecastInsights: 'Data Forecast Insights',
      pocketAnaylsis: 'Pocket Analysis',
      demandForecast: 'Demand Forecast',
      macroeconomicModel: 'Macroeconomic model'
    },

    smartOrder: {
      title: 'Smart Order',
      quantitative: 'Quantitative analysis',
      orderList: 'Order List',
      qualitative: 'Independent probabilities',
      demand: 'Demand Forecast'
    }
  },
  filters: {
    clear: {
      label: 'Reset filters'
    },
    apply: {
      label: 'Apply filters'
    }
  },
  download: {
    title: 'Download',
    options: {
      pdf: 'Download in PDF',
      xls: 'Download in XLS (excel)'
    }
  },
  utils: {
    months: 'Months',
    car: 'car',
    cars: 'cars',
    return: 'return',
    resultsPerPage: 'Results per page',
    showing: 'Showing',
    of: 'of',
    items: 'items',
    nodata: 'No data available for selected parameters',
    twohundred: 'Error 204: No content',
    fourhundred: 'Page not found',
    fivehundred: 'Internal server error',
    fourhundredSubtitle: `Oops! We can't seem to find the page you're looing for`,
    fivehundredSubtitle: `Oops, something went wrong! Try to refresh de page or feel free to contact us if problem persists`,
    view: 'View',
    delete: 'Delete'
  },
  home: {
    title: 'Welcome to',
    boldTitle: 'Intelligent Sales',
    tools: {
      title: 'Select Tool',
      firstOption: 'Intelligent Pricing',
      secondOption: 'Smart Order'
    },
    brands: {
      title: 'Select Brand',
      firstOption: 'Cupra',
      secondOption: 'Seat'
    },
    button: 'Access the platform'
  },
  smartOrder: {
    header: {
      keyMonth: 'Key Month',
      keyMonthInfo: 'You may see an increase in production on key months.',
      selectPlaceholder: 'Models'
    },
    dashboard: {
      title: 'Smart Order',
      panels: {
        salesForecast: {
          title: 'Sales & Forecasting',
          units: 'units forecasted this month'
        },
        stockDetails: {
          title: 'Stock details',
          units: 'units in stock this month'
        },
        fuCalendar: {
          title: 'FU calendar & restrictions'
        },
        quantitative: {
          title: 'Quantitative Analysis',
          caption: 'Data source: SLI & Sales objectives dealers',
          filters: {
            byName: 'By name',
            byValue: 'By value'
          },
          units: 'total car units'
        },
        qualitative: {
          title: 'Qualitative Analysis',
          caption: 'Data source: SLI & Sales objectives dealers',
          filters: {
            byName: 'By name',
            byValue: 'Optional pack'
          },
          units: 'total car units'
        },
        recommendationOrder: {
          title: 'Order List Recommendation',
          caption: 'Data source: SLI & Sales objectives dealers'
        }
      }
    },
    salesForecast: {
      title: 'Sales & Forecasting',
      filters: {
        campa: 'Campa'
      },
      panels: {
        recommendation: {
          title: 'Sales & forecast recommendation',
          caption: 'Data source: SLI'
        },
        perTrim: {
          title: 'Sales forecast per trim',
          caption: 'Data source: SLI'
        },
        parameters: {
          title: 'Forecasting parameters',
          caption: 'Data source: SIMMIX & JATO'
        }
      }
    },
    stockDetails: {
      title: 'Stock details',
      filters: {
        fuel: 'Fuel',
        modelYear: 'Model year',
        campa: 'Campa',
        stockType: 'Stock type'
      },
      download: 'Download VINS',
      panels: {
        details: {
          title: 'Stock details',
          caption: 'Data source: SLI'
        },
        daysInStock: {
          title: 'Days in stock of current stock',
          caption: 'Data source: SLI'
        },
        historical: {
          title: 'Stock historical',
          caption: 'Data source: SLI'
        }
      }
    },
    fuCalendar: {
      title: 'FU calendar & restrictions',
      panels: {
        fuTable: {
          title: 'FU Calendar'
        },
        fuRestrictions: {
          title: 'Restrictions'
        },
        fuCoverage: {
          title: 'Coverage'
        }
      }
    },
    quantitative: {
      title: 'Quantitative analysis',
      panels: {
        recommended: {
          title: 'Campa recommended order',
          caption: 'Data source: SLI & Sales objectives dealers',
          totalCars: 'Total Cars',
          total: 'Total',
          campa: 'Campa'
        },
        evolution: {
          title: 'Campa evolution',
          caption: 'Data source: SLI & Sales objectives dealers'
        }
      }
    },
    qualitative: {
      title: 'Qualitative analysis',
      panels: {
        optionalPack: {
          title: 'Optional pack'
        },
        interiorColor: {
          title: 'Interior Color'
        },
        exteriorColor: {
          title: 'Exterior Color'
        },
        engine: {
          title: 'Engine'
        },
        tmaimg: {
          title: 'TMAIMG'
        },
        fuel: {
          title: 'Fuel'
        },
        optional: {
          title: 'Optionals'
        }
      }
    },
    recommendationOrder: {
      title: 'Order List',
      filters: {
        campa: 'Campa'
      },
      panels: {
        recommendation: {
          title: 'Monthly Recommendation Order',
          caption: 'Data source: SLI & Sales objectives dealers'
        }
      }
    }
  },
  pricing: {
    header: {
      linkNames: {
        results: 'Results',
        registrationPositioning: 'Registration Positioning',
        pricePositioning: 'Price Positioning',
        lastCampaign: 'last campaign',
        createCampaign: 'Campaign definition'
      }
    },
    createCampaign: {
      summary: {
        title: 'Campaign summary',
        create: 'Create new campaign',
        filters: {
          model: 'Car Model',
          period: 'Campaign period range',
          objective: 'Campaign objective'
        },
        panels: {
          campaignsList: {
            title: 'List of Campaigns',
            caption: 'Data source: SLI & Sales objectives dealers'
          }
        }
      },

      definition: {
        title: 'Campaign definition',
        create: {
          title: 'New campaign',
          campaignTitle: 'Campaign title',
          campaignSelect: 'Select your campaign',
          selectModelPlaceholder: 'Models',
          setupSelect: 'Setup',
          cancel: 'Cancel',
          createCampaign: 'Create campaign',
          stepName: 'Creation campaign',
          formError: 'Field cannot be empty.'
        },
        configure: {
          stepName: 'Configuration campaign',
          table: {
            title: 'Results',
            objectiveSelector: 'Campaign objective',
            filterSelector: 'Filter by',
            hideFilters: 'Hide all filters'
          },
          tableEdit: {
            title: 'Campaign definition',
            base: 'Base',
            financing: 'Financing',
            tradein: 'Trade In',
            product: 'Product',
            partners: 'Partners',
            stock: 'Stock',
            addRow: '+ Add row'
          }
        },
        results: {
          stepName: 'Results campaign'
        }
      }
    },
    dataForecastInsights: {
      title: 'Data Forecast Insights',
      densityHistogramToolip:
        ' The vertical scale of a frequency histogram shows the number of observations in each bin. The vertical scale of a density histogramshows units that make the total area of all the bars add to 1',
      trendInputChartTooltipFirstTitle: '95% Confidence interval:',
      trendInputChartTooltipFirst:
        ' If we were to take 100 different samples and compute a 95% confidence interval for each sample, then approximately 95 of the 100 confidence intervals will contain the true mean value',
      trendInputChartTooltipSecondTitle: 'Polynomial Regression:',
      trendInputChartTooltipSecond:
        'The relationship between the independent variables and dependent variables are modeled in the nth degree polynomial',
      trendInputChartTooltipThirdTitle: 'Input Data:',
      trendInputChartTooltipThird: 'Target Variable',
      noResults: 'Select some input from filters...'
    },
    pocketAnaylsis: {
      title: 'Pocket Analysis'
    },
    lastCampaign: {
      title: 'Last Campaign'
    },
    pricePositioning: {
      title: 'Price Positioning'
    },
    registrationPositioning: {
      title: 'Registration Positioning'
    },
    results: {
      title: 'Results'
    },
    demandForecast: {
      title: 'Demand Forecast'
    }
  },
  components: {
    submitStatus: {
      submit: 'submitted',
      draft: 'draft'
    }
  }
}
