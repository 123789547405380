import { api } from 'api/api'

export const getDatesAvailable = async (uuid: string) => {
  const response = await api.get(
    '/macroeconomic/registrations-forecast-input-data/available-dates',
    '',
    uuid,
    1
  )
  return response
}

export const getInputsAvailable = async (uuid: string) => {
  const response = await api.get(
    '/macroeconomic/registrations-forecast-input-data/macro-variables-list',
    '',
    uuid,
    1
  )
  return response
}

export const getRegistrationsForecast = async (uuid: string, queryParams) => {
  const response = await api.get(
    '/macroeconomic/registrations-forecast',
    queryParams,
    uuid,
    1
  )
  return response
}
