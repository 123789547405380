import { create } from 'zustand'
import zustymiddleware from 'zustymiddleware'

interface FilterValue {
  label: 'string'
  value: number | string
}

export interface MacroEconomicfilters {
  input?: null | FilterValue[]
  periodStart?: null | string
  periodEnd?: null | string
}
const macroeconomicFilters: MacroEconomicfilters = {
  input: null,
  periodStart: null,
  periodEnd: null
}

declare global {
  interface Window {
    store: any
  }
}

export const useMacroeconomicStore = create(
  zustymiddleware((set) => ({
    ...macroeconomicFilters,
    updateFilter: (filterName: string, value: number[] | string[]) => {
      if (typeof value === 'object') {
        set(() => ({ [filterName]: value }))
      }
    },
    resetFilter: (filterName: string) => {
      set(() => ({ [filterName]: macroeconomicFilters[filterName] }))
    },
    setCustomObjetive: (value: string | number) =>
      set(() => ({ customObjective: value }))
  }))
)

window.store = useMacroeconomicStore
