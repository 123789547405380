import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: sticky;
  top: 0;
  right: 0;
  padding: 16px 20px;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dedede80;
  background-color: #fff;
`

export const TitleWrapper = styled.div`
  color: #000;
  font-weight: normal;
  display: flex;
  align-items: center;
`

export const TitleMain = styled.h1`
  font-size: 1.5rem;
  font-weight: 500;
`

export const TitleBreadCrumb = styled(Link)`
  font-size: 1.4rem;
  font-weight: normal;
  text-decoration: none;
  &:hover,
  &:visited,
  &:active {
    color: inherit;
  }
  &::before {
    content: '/';
    padding: 0 0.3rem;
  }
`

export const Menu = styled.button`
  background-color: white;
  height: 2.4rem;
  width: 2.4rem;
  cursor: pointer;
`
