import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 10px;
  }

  body {
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: normal;
    overflow-x: hidden;
  }

  button, input {
    font-weight: normal;
  }

  p {
    font-size: 1.4rem;
    margin-bottom: 0.8em;
  }

  ul {
    list-style: none;
  }

  abbr[title] {
    text-decoration: none;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }

  table {
    border-collapse: collapse;
  }

  h1 {
    font-size: 40px;
    line-height: 42px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background: #000;
    width: 36px;
    height: 36px;

    &::after {
      font-size: 20px;
      color: #fff;
    }
   
  }

  .swiper-button-disabled {
    display: none;
  }

  #tv-attr-logo {
    display: none !important;
  }
`

export { GlobalStyles }
