/* eslint-disable react-hooks/exhaustive-deps */
import { BrandContext } from 'contexts/BrandProvider'
import { useContext, useEffect } from 'react'
import { ReactComponent as CupraIcon } from 'assets/icons/cupra-logo.svg'
import { ReactComponent as SeatIcon } from 'assets/icons/seat-logo.svg'
import {
  Wrapper,
  SwitchLabel,
  SwitchInput,
  SwitchSlider,
  BrandContainer
} from './BrandSwitch.style'
import { useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'

const BrandSwitch = () => {
  const { pathname } = useLocation()
  const { brand, setBrand } = useContext(BrandContext)
  const [, setCookie] = useCookies(['brandSelected'])

  const isSeat = brand === 'seat'

  const handleToggle = () => {
    if (isSeat && pathname.includes('smart-order')) return
    setBrand(isSeat ? 'cupra' : 'seat')
    setCookie('brandSelected', isSeat ? 'cupra' : 'seat')
  }

  const changeFavicon = (favicon: string) => {
    let link: HTMLLinkElement | null =
      document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.head.appendChild(link)
    }
    link.href = favicon
  }

  useEffect(() => {
    const favicon = `${process.env.PUBLIC_URL}/favicon-${brand}.ico`
    setBrand(brand)
    changeFavicon(favicon)
  }, [brand])

  useEffect(() => {
    if (brand) setBrand(brand)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper>
      <SwitchLabel isSeat={isSeat}>
        <SwitchInput
          type="checkbox"
          checked={!isSeat}
          onChange={handleToggle}
        />
        <BrandContainer>
          <SeatIcon />
          <CupraIcon />
        </BrandContainer>
        <SwitchSlider isSeat={isSeat} />
      </SwitchLabel>
    </Wrapper>
  )
}

export { BrandSwitch }
