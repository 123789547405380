import { Navigate, Route, Routes } from 'react-router-dom'
import { lazy, memo, Suspense } from 'react'
import {
  INTELLIGENT_SALES,
  SMART_ORDER,
  SMART_ORDER_QUALITATIVE,
  SMART_ORDER_QUANTITATIVE,
  SMART_ORDER_RECOMMENDATION_ORDER,
  SMART_ORDER_DEMAND_FORECAST,
  PRICING,
  PRICING_CREATE_CAMPAIGN,
  DATA_FORECAST_INSIGHTS,
  MTF_COMPARATIVE,
  PRICING_NEW_CAMPAIGN,
  PRICING_LAST_CAMPAIGN,
  PRICING_PRICE_POSITIONING,
  PRICING_REGISTRATION_POSITIONING,
  PRICING_RESULTS,
  POCKET_ANALYSIS,
  DEMAND_FORECAST,
  MACROECONOMIC_MODEL,
  DASHBOARD
} from 'constants/paths'
import { Spinner } from 'components/Spinner'
import { SmartOrder } from './SmartOrder'
import { Pricing } from './Pricing'
import DataForecastInsights from './Pricing/views/DataForecastInsights'
import PocketAnalysis from './Pricing/views/PocketAnalysis'
import DemandForecast from './Pricing/views/DemandForecast'
import { Dashboard } from './Dashboard/Dashboard'
import { DashboardByModel } from './Dashboard/Components/DashboardByModel'
import { MacroeconomicModel } from './Pricing/views/MacroeconomicModel/MacroeconomicModel'
import ScrollToTop from './ScrollToTop'

const IntelligentSales = lazy(() => import('./IntelligentSales'))

const SmartOrderQuantitative = lazy(
  () => import('./SmartOrder/views/Quantitative')
)
const SmartOrderQualitative = lazy(
  () => import('./SmartOrder/views/Qualitative')
)
const SmartOrderOrderRecommendation = lazy(
  () => import('./SmartOrder/views/OrderRecommendation')
)

const SmartOrderDemandForecast = lazy(() => import('./SmartOrder/views/Demand'))

const PricingNewCampaign = lazy(
  () => import('./Pricing/views/CreateCampaign/Definition')
)
const PricingResults = lazy(() => import('./Pricing/views/Results'))
const PricingLastCampaign = lazy(() => import('./Pricing/views/LastCampaign'))
const PricingPricePositioning = lazy(
  () => import('./Pricing/views/PricePositioning')
)
const PricingRegistrationPositioning = lazy(
  () => import('./Pricing/views/RegistrationPositioning')
)

const MainRouter = memo(() => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path={DASHBOARD}
          element={
            <Suspense fallback={<Spinner />}>
              <Dashboard />
            </Suspense>
          }
        />
        <Route
          path={INTELLIGENT_SALES}
          element={
            <Suspense fallback={<Spinner />}>
              <IntelligentSales />
            </Suspense>
          }
        />

        <Route path={SMART_ORDER} element={<SmartOrder />}>
          <Route
            path={SMART_ORDER_QUANTITATIVE}
            element={
              <Suspense fallback={<Spinner />}>
                <SmartOrderQuantitative />
              </Suspense>
            }
          />
          <Route
            path={SMART_ORDER_QUALITATIVE}
            element={
              <Suspense fallback={<Spinner />}>
                <SmartOrderQualitative />
              </Suspense>
            }
          />

          <Route
            path={SMART_ORDER_DEMAND_FORECAST}
            element={
              <Suspense fallback={<Spinner />}>
                <SmartOrderDemandForecast />
              </Suspense>
            }
          />
          <Route
            path={SMART_ORDER_RECOMMENDATION_ORDER}
            element={
              <Suspense fallback={<Spinner />}>
                <SmartOrderOrderRecommendation />
              </Suspense>
            }
          />
        </Route>

        {/* Intelligent Pricing */}
        <Route path={PRICING} element={<Pricing />}>
          <Route index element={<Navigate to={PRICING_RESULTS} />} />
          <Route
            path={PRICING_CREATE_CAMPAIGN + '/' + PRICING_NEW_CAMPAIGN}
            element={
              <Suspense fallback={<Spinner />}>
                <PricingNewCampaign />
              </Suspense>
            }
          />
          <Route
            path={DATA_FORECAST_INSIGHTS}
            element={
              <Suspense fallback={<Spinner />}>
                <DataForecastInsights mtf={false} />
              </Suspense>
            }
          />
          <Route
            path={MTF_COMPARATIVE}
            element={
              <Suspense fallback={<Spinner />}>
                <DataForecastInsights mtf />
              </Suspense>
            }
          />
          <Route
            path={POCKET_ANALYSIS}
            element={
              <Suspense fallback={<Spinner />}>
                <PocketAnalysis />
              </Suspense>
            }
          />
          <Route
            path={DEMAND_FORECAST}
            element={
              <Suspense fallback={<Spinner />}>
                <DemandForecast />
              </Suspense>
            }
          />
          <Route
            path={MACROECONOMIC_MODEL}
            element={
              <Suspense fallback={<Spinner />}>
                <MacroeconomicModel />
              </Suspense>
            }
          />
          <Route
            path={PRICING_CREATE_CAMPAIGN + '/' + PRICING_NEW_CAMPAIGN}
            element={
              <Suspense fallback={<Spinner />}>
                <PricingNewCampaign />
              </Suspense>
            }
          />
          <Route
            path={PRICING_LAST_CAMPAIGN}
            element={
              <Suspense fallback={<Spinner />}>
                <PricingLastCampaign />
              </Suspense>
            }
          />
          <Route
            path={PRICING_PRICE_POSITIONING}
            element={
              <Suspense fallback={<Spinner />}>
                <PricingPricePositioning />
              </Suspense>
            }
          />
          <Route
            path={PRICING_REGISTRATION_POSITIONING}
            element={
              <Suspense fallback={<Spinner />}>
                <PricingRegistrationPositioning />
              </Suspense>
            }
          />
          <Route
            path={PRICING_RESULTS}
            element={
              <Suspense fallback={<Spinner />}>
                <PricingResults />
              </Suspense>
            }
          >
            <Route
              path=":campaignId"
              element={
                <Suspense fallback={<Spinner />}>
                  <PricingResults />
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path={`${DASHBOARD}/:modelo`}
          element={
            <Suspense fallback={<Spinner />}>
              <DashboardByModel />
            </Suspense>
          }
        />
      </Routes>
    </>
  )
})

export { MainRouter }
