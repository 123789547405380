import { User } from 'components/User'
import {
  DATA_FORECAST_INSIGHTS,
  PRICING,
  SMART_ORDER,
  SMART_ORDER_QUANTITATIVE
} from 'constants/paths'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TitleBreadCrumb,
  TitleMain,
  TitleWrapper,
  Wrapper
} from './Header.style'
import { useLocation } from 'react-router-dom'
import { BrandSwitch } from 'components/Inputs/Brand/BrandSwitch'
import { CustomHeader } from 'layout/CustomHeader/CustomHeader'

const Header = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [appModule, setAppModule] = useState<string | null>(null)

  useEffect(() => {
    const getModule = (pathname: string) => {
      if (pathname.includes('smart-order')) {
        return 'smartOrder'
      }
      if (pathname.includes('intelligent-pricing')) {
        return 'pricing'
      }
      return ''
    }
    const currentModule = getModule(pathname)
    setAppModule(currentModule)
  }, [pathname])

  if (pathname === '/') return null

  const config = {
    smartOrder: {
      title: 'Smart Order',
      to: SMART_ORDER + '/' + SMART_ORDER_QUANTITATIVE
    },
    pricing: {
      title: 'Intelligent Pricing',
      to: PRICING + '/' + DATA_FORECAST_INSIGHTS
    }
  }

  return (
    <Wrapper data-testid="appheader-component">
      <TitleWrapper>
        <BrandSwitch />
        <TitleMain>{t('appTitle')}</TitleMain>
        {appModule && (
          <TitleBreadCrumb to={config[appModule]?.to}>
            {config[appModule].title}
          </TitleBreadCrumb>
        )}
      </TitleWrapper>
      <CustomHeader />
      <User />
    </Wrapper>
  )
}

export { Header }
