import {
  getDatesAvailable,
  getInputsAvailable
} from 'api/pricing/macroeconomic'
import { Filters } from 'components/Filters'
import { Select } from 'components/Inputs/Select'
import { useTokenUuid } from 'hooks/request'
import {
  useRef,
  MutableRefObject,
  useCallback,
  useState,
  useEffect
} from 'react'
import { useMacroeconomicStore } from 'state'
import { shallow } from 'zustand/shallow'

const PageFilters = () => {
  const uuid = useTokenUuid()
  const periodEndRef: MutableRefObject<any> = useRef('periodEnd')
  const periodStartRef: MutableRefObject<any> = useRef('periodStart')
  const inputRef: MutableRefObject<any> = useRef('input')

  const { input, periodStart, periodEnd, updateFilter, resetFilter } =
    useMacroeconomicStore(
      (state: any) => ({
        input: state.inputForecastInput,
        periodStart: state.periodStart,
        periodEnd: state.periodEnd,
        updateFilter: state.updateFilter,
        resetFilter: state.resetFilter
      }),
      shallow
    )

  const [valuePeriodEnd, setValuePeriodEnd] = useState<any>(periodEnd)
  const [valuePeriodStart, setValuePeriodStart] = useState<any>(periodStart)
  const [valueInput, setValueInput] = useState<any>(input)

  const [inputsList, setInputsList] = useState<any[]>([])

  const fetchDatesAvaible = async () => {
    const resp: any = await getDatesAvailable(uuid)
    if (resp !== 500 && resp !== 204 && resp !== 404) {
      setValuePeriodStart(new Date(resp.initial_date))
      setValuePeriodEnd(new Date(resp.end_date))
    }
  }

  const fetchInputsAvaible = async () => {
    const resp: any = await getInputsAvailable(uuid)
    if (resp !== 500 && resp !== 204 && resp !== 404) {
      setInputsList(resp.filter_list)
    }
  }

  const updateStateFilter = useCallback((key: string, value: string) => {
    switch (key) {
      case 'periodEnd':
        console.log(value)
        break
      case 'periodStart':
        console.log(value)
        break
      case 'input':
        setValueInput(value)
        break
    }
  }, [])

  const applyAll = useCallback(() => {
    updateFilter('periodEnd', valuePeriodEnd)
    updateFilter('periodStart', valuePeriodStart)
    updateFilter('input', valueInput)
  }, [updateFilter, valuePeriodEnd, valuePeriodStart, valueInput])

  const clearAll = useCallback(() => {
    periodEndRef.current.clear()
    periodStartRef.current.clear()
    inputRef.current.clear()
    resetFilter('periodEnd')
    resetFilter('periodStart')
    resetFilter('inputs')
  }, [resetFilter])

  const enableApplyAll = useCallback(() => {
    return valueInput && !(input === valueInput)
  }, [input, valueInput])

  const totalFilters = useCallback(() => {
    const hasInput = +!!input
    const hasPeriodEnd = +!!periodEnd
    const hasPeriodStart = +!!periodStart
    return hasInput + hasPeriodEnd + hasPeriodStart
  }, [input, periodEnd, periodStart])

  useEffect(() => {
    uuid && fetchDatesAvaible()
    uuid && fetchInputsAvaible()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid])

  return (
    <Filters
      applyAll={applyAll}
      clearAll={clearAll}
      enableApplyAll={enableApplyAll()}
      totalFilters={totalFilters()}
    >
      {/* <Datepicker
        minDate={valuePeriodStart}
        maxDate={valuePeriodEnd}
        emptyPlaceholder="Min period month"
        reference={periodStartRef}
        defaultValue={periodStart}
        onUpdateValue={updateStateFilter}
        fixedWidth="210px"
        id="periodStart"
      />

      <Datepicker
        minDate={valuePeriodStart}
        maxDate={valuePeriodEnd}
        emptyPlaceholder="Max period month"
        reference={periodEndRef}
        defaultValue={periodEnd}
        onUpdateValue={updateStateFilter}
        fixedWidth="210px"
        id="maxdate"
      /> */}
      <Select
        minWidth={270}
        options={inputsList}
        emptyPlaceholder="Inputs"
        reference={inputRef}
        defaultValue={input}
        onUpdateValue={updateStateFilter}
        id="input"
        isMulti
      />
    </Filters>
  )
}

export { PageFilters }
