/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  createChart,
  IChartApi,
  ISeriesApi,
  MouseEventParams
} from 'lightweight-charts'
import { Card } from 'components/Card'
import { Col } from 'components/Grid/Col'
import { Row } from 'components/Grid/Row'
import { Title } from 'components/Title'
import { Container } from 'layout/Content/Content.style'
import { PageFilters } from './components/PageFilters'
import { useMacroeconomicStore } from 'state/macroeconomic'
import { getRegistrationsForecast } from 'api/pricing/macroeconomic'
import { useTokenUuid } from 'hooks/request'
import { toQueryParams } from 'api/utils'
import { ReactComponent as NoResults } from 'assets/icons/noResults.svg'
import { Spinner } from 'components/Spinner'

const MacroeconomicModel = () => {
  const uuid = useTokenUuid()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const chartContainerRef = useRef<HTMLDivElement | null>(null)
  const [tooltip, setTooltip] = useState<{
    time: string
    value: string
    x: number
    y: number
  } | null>(null)
  const [data, setData] = useState<any>()

  const params = useMacroeconomicStore()

  let queryParams = toQueryParams({
    start_month: new Date(params.periodStart).getMonth() + 1,
    start_year: new Date(params.periodStart).getFullYear(),
    end_month: new Date(params.periodEnd).getMonth() + 1,
    end_year: new Date(params.periodEnd).getFullYear(),
    prediction_months: 4,
    macro_vars_list: params?.input?.map((item) => item.value).join(',')
  })

  const fetchData = async () => {
    setIsLoading(true)
    const resp: any = await getRegistrationsForecast(
      uuid,
      `${queryParams},registrations`
    )
    if (resp !== 500 && resp !== 204 && resp !== 404) {
      setData(resp)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (params.input && params.periodStart && params.periodEnd)
      uuid && fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, queryParams])

  useEffect(() => {
    if (!chartContainerRef.current) return

    const chart: IChartApi = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        textColor: 'black',
        fontFamily: 'Cupra'
      },
      leftPriceScale: {
        visible: true
      },
      rightPriceScale: {
        visible: true
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 0.8)',
        tickMarkFormatter: (time: string) => {
          if (!time) return ''
          const [year, month] = time.split('-')
          const monthName = new Date(`${year}-${month}-01`).toLocaleString(
            'default',
            { month: 'short' }
          )
          return `${monthName}/${year}`
        }
      }
    })

    chart.applyOptions({
      crosshair: {
        mode: 1
      }
    })

    const seriesMap: Record<string, ISeriesApi<'Line'>> = {}

    if (data) {
      data.list_of_macro_var_points.forEach((macroVarData, index) => {
        const transformedData = macroVarData.macro_var_points.map((point) => ({
          time: `${point.year}-${String(point.month).padStart(2, '0')}-01`,
          value: point.point
        }))

        const colorPalette = ['#D8B2ED', '#95572B', '#003972', '#dc3444']
        const series: ISeriesApi<'Line'> = chart.addLineSeries({
          color: colorPalette[index % colorPalette.length],
          lineWidth: 2,
          priceScaleId: index === params.input.length ? 'right' : 'left',
          title: macroVarData.macro_var
        })

        series.setData(transformedData)

        seriesMap[macroVarData.macro_var] = series
      })
    }

    chart.timeScale().fitContent()

    // Tooltip func
    const onCrosshairMove = (param: MouseEventParams) => {
      if (!param || !param.time || !param.seriesData) {
        setTooltip(null)
        return
      }

      const time = param.time.toString()
      const x = param?.point?.x || 0
      const y = param?.point?.y || 0

      const tooltipValues = Object.entries(seriesMap).map(
        ([key, series]) =>
          `${key}: ${(param.seriesData.get(series) as any)?.value || 'N/A'}`
      )

      setTooltip({
        time,
        value: tooltipValues.join('<br />'),
        x,
        y
      })
    }

    const handleMouseEnter = () => {
      chart.subscribeCrosshairMove(onCrosshairMove)
    }

    const handleMouseLeave = () => {
      chart.unsubscribeCrosshairMove(onCrosshairMove)
      setTooltip(null)
    }

    const chartContainer = chartContainerRef.current
    if (chartContainer) {
      chartContainer.addEventListener('mouseenter', handleMouseEnter)
      chartContainer.addEventListener('mouseleave', handleMouseLeave)
    }

    const resizeObserver = new ResizeObserver(() => {
      chart.applyOptions({
        width: chartContainerRef.current?.clientWidth || 0
      })
    })

    resizeObserver.observe(chartContainerRef.current)

    return () => {
      if (chartContainer) {
        chartContainer.removeEventListener('mouseenter', handleMouseEnter)
        chartContainer.removeEventListener('mouseleave', handleMouseLeave)
      }
      resizeObserver.disconnect()
      chart.remove()
    }
  }, [data])

  return (
    <>
      {isLoading && <Spinner />}
      <Container>
        <Title label={'Macroeconomic Model'} data-testid="dashboard-view" />
        <Row>
          <PageFilters />
        </Row>

        {data && (
          <Row>
            <Col>
              <Card
                headerTitle="Macroeconomic Model"
                subtitle="Registration forecasting macroeconomic model"
              >
                <div style={{ position: 'relative' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        writingMode: 'vertical-rl',
                        transform: 'rotate(180deg)',
                        textAlign: 'center',
                        marginRight: '8px',
                        color: 'black',
                        fontSize: '10px'
                      }}
                    >
                      {params?.input?.map((item, index) => (
                        <p style={{ fontSize: '10px' }} key={index}>
                          {item.label}
                        </p>
                      ))}
                    </div>

                    <div
                      ref={chartContainerRef}
                      style={{
                        flex: 1,
                        position: 'relative',
                        height: '300px',
                        zIndex: 0
                      }}
                    ></div>

                    <div
                      style={{
                        writingMode: 'vertical-rl',
                        textAlign: 'center',
                        marginLeft: '8px',
                        color: 'black',
                        fontSize: '10px'
                      }}
                    >
                      Registrations
                    </div>
                  </div>

                  {tooltip && (
                    <div
                      style={{
                        position: 'absolute',
                        top: `${tooltip.y + 10}px`,
                        left: `${tooltip.x + 10}px`,
                        color: 'black',
                        zIndex: 999,
                        borderColor: '#f5f5f5',
                        padding: '0.8rem 1.6rem',
                        borderRadius: '10px',
                        background: '#f5f5f5',
                        fontSize: '12px'
                      }}
                    >
                      <strong>{tooltip.time}</strong>
                      <br />
                      {tooltip?.value.split('<br />').map((line, index) => (
                        <div key={index}>{line}</div>
                      ))}
                    </div>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        )}

        {(data === undefined || data.length === 0) && !isLoading && (
          <div style={{ textAlign: 'center' }}>
            <NoResults />
            <h3 style={{ fontWeight: 400 }}>
              Select some input from filters...
            </h3>
          </div>
        )}
      </Container>
    </>
  )
}

export { MacroeconomicModel }
